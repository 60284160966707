import React from "react";
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { graphql } from 'gatsby';
import { getSrc } from "gatsby-plugin-image"

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import ThumbUp from "@material-ui/icons/ThumbUp";
import BusinessCenter from "@material-ui/icons/BusinessCenter";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import SocialButtons from "components/SocialButtons/SocialButtons.js";

import Parallax from "components/Parallax/Parallax.js";
import { Typography } from "@material-ui/core";

// import header + footer
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";

import useIsClient from 'hooks/useIsClient';

import ContactUsModal from 'components/Footer/Modals/ContactUsModal.js';

import contactUsStyle from "assets/jss/views/contactUsStyleCustom.js";

export const query = graphql`
  query {
    bannerImage: imageSharp(resize: {originalName: {eq: "scentsy-radiate_positivity.jpg"}}) {
      gatsbyImageData(quality: 50)
    }
    bannerImageMobile: imageSharp(resize: {originalName: {eq: "scentsy-radiate_positivity.jpg"}}) {
      gatsbyImageData(width: 1200)
    }
  }
`

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsTemplate(props) {

  const classes = useStyles();

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.bannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.bannerImage)
    }
  }

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

  return (
    <div>
      <GatsbySeo
        title={'Contact Us for Scentsy' + props.pageContext.locale.appendToTitle}
        description={'Contact us now. We can help you select and customize the perfect fragrance experience for your home, office, home office, car, or vacation.'}
        canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
        language={props.pageContext.locale.isoLanguageCode}
        languageAlternates={[
          {
            hrefLang: props.pageContext.locale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
          {
            hrefLang: props.pageContext.altLocale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
        }
        ]}
        openGraph={{
          type: 'website',
          title: 'Contact Us for Scentsy' + props.pageContext.locale.appendToTitle,
          description: 'Contact us now. We can help you select and customize the perfect fragrance experience for your home, office, home office, car, or vacation.',
          url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          site_name: 'SCENTSY Online Store | Home Fragrance Biz',
          locale: props.pageContext.locale.ogLanguageCode,
          images: [
            {
              url: siteMetadata.siteUrl + '/files/images/Home_Fragrance_Biz-Independent_Scentsy_Consultant-Logo-rectangle.png',
              alt: 'Home Fragrance Biz Logo',
              width: 750,
              height: 450
            }]
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
            item: 'https://homefragrancebiz.com',
          },
          {
            position: 2,
            name: 'Contact Us',
          },
        ]}
      />
      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />
      <Parallax
        filter={"dark"}
        image={bannerImage}
        style={{ maxHeight: "800px", paddingTop: "3%" }}
      >
        <div className={classes.container}>
          <HeaderScentsy />

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.brand}>
                <Typography variant="h5" component="p" className={classes.titleDescription}>
                  What can we help you with?
                </Typography>
                <Typography variant="h3" component="h1" className={classes.title}>
                Contact Us
                </Typography>
                <Typography variant="h6" component="h2" className={classes.titleDescription}>
                We can help you select and customize the perfect fragrance experience
                for your home, office, home office, car, or vacation.
                </Typography>
              </div>
            </GridItem>
          </GridContainer>
        </div>

      </Parallax>
      
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.description}>Send us a message</h2>
            <GridContainer>
              <GridItem xs={12} sm={6} md={5}>
                <p className={classes.description}>
                 Please call or contact us to schedule an appointment or on-site consultation.
                </p>
                {/**<p>
                  <font size='1'>
                  Enter your e-mail and opt-in below to receive Home Fragrance Biz's newsletter containing news, updates and promotions regarding Scentsy products. You can withdraw your consent at any time.
                  </font>
                </p>
                <p>
                  <font size='1'>
                  Yes! I want to receive commercial electronic messages from Home Fragrance Biz Inc. | 15-75 Bayly Street West (Suite 170) Ajax, ON L1S 7L7 Canada | homefragrancebiz.com
                  </font>
                </p>**/}
                {/**<iframe src="https://us18.list-manage.com/contact-form?u=0d02179f33edacefc7e04ab55&form_id=2a13afd803752642ae594695424d03d9" width="500" height="850"></iframe>**/}
                <ContactUsModal />

              </GridItem>
              <GridItem xs={12} sm={4} md={5} className={classes.mlAuto}>

              <InfoArea
                  className={classes.info}
                  title="Social media"
                  description={
                    <SocialButtons />
                  
                  }
                  icon={ThumbUp}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Give us a ring"
                  description={
                    <p>
                      +1 844 944 3348<br />8:00-22:00 ET
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                />
                                
                <InfoArea
                  className={classes.info}
                  title="Mailing address"
                  description={
                    <p>
                      15-75 Bayly Street West (Suite 170)<br />
                      Ajax, Ontario. L1S 7L7<br />
                      Canada <i>(By appointment only)</i>
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
                
                <InfoArea
                  className={classes.info}
                  title="Legal Information"
                  description={
                    <p>
                      Home Fragrance Biz Inc.<br />
                      operating as Home Fragrance Biz<br />
                      Independent Scentsy Consultant
                    </p>
                  }
                  icon={BusinessCenter}
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <FooterTemplate
        locale={props.pageContext.locale}
      />
    </div>
  );
}
