import React from "react";

import IconButton from '@material-ui/core/IconButton';

// font awesome icons
import { FaFacebookSquare, FaInstagram, FaYoutubeSquare } from "react-icons/fa";

export default function SocialButtons() {
  return (
    <div>
      <center>
        <a href='https://www.facebook.com/homefragrancebiz' target="_blank" rel="nofollow noreferrer" color="inherit" >
          <IconButton aria-label="Facebook">
            <FaFacebookSquare  />

          </IconButton>  
        </a>

        <a href='https://www.instagram.com/homefragrancebiz/' target="_blank" rel="nofollow noreferrer" color="inherit" >
          <IconButton aria-label="Instagram">
            <FaInstagram />

          </IconButton>
        </a>

        <a href='https://www.youtube.com/c/Homefragrancebiz' target="_blank" rel="nofollow noreferrer" color="inherit" >
          <IconButton aria-label="Youtube">
            <FaYoutubeSquare />

          </IconButton>
        </a>
      </center>
    </div>
  );
}
